<!-- eslint-disable vue/valid-v-model -->
<template>
  <div class="view">
    <ais-configure
      v-bind:hits-per-page.camel="5"
      :facetFilters="['title:' + title]"
      :query="aisQuery"
    ></ais-configure>

    <ais-state-results>
      <template #default="searchState">
        <div class="artist-page__content">
          <div class="filter-icon__container">
            <a
              @click.prevent="onFilterClick"
              href=""
              class="filter-link"
              :class="{ active: showFilter }"
            >
              <img class="filter-icon" :src="filterIcon" alt="Filter" />
            </a>
          </div>
          <router-link to="/" class="back"
            ><img
              class="desktop_form_close"
              src="@/assets/img/close.svg"
              alt="close"
          /></router-link>

          <form class="group">
            <label><strong>Title:</strong></label>
            <!-- <span class="preface">{{ $route.params.title }}</span> -->
            <div ref="headlineContainer" class="headline__container">
              <BaseHeadline
                maxLength="55"
                ref="headline"
                :text="$route.params.title"
              />
              <ais-search-box v-if="screen.width > 767">
                <template #default="{ currentRefinement, refine }">
                  <div class="filter-input__container">
                    <input
                      class="filter-input"
                      type="search"
                      :class="{ active: showFilter }"
                      v-model="currentRefinement"
                      @input="refine($event.currentTarget.value)"
                      placeholder="type to filter"
                      id="title_search"
                    />
                  </div>
                </template>
              </ais-search-box>
            </div>
          </form>

          <div class="group">
            <!-- TODO: Add count for titles (facets) -->
            <label class="titles-headline__container"
              ><strong>Artists</strong> (<span>{{
                getTitlesCount(searchState)
              }}</span
              >):</label
            >

            <div class="mobile-filter__container">
              <div class="mobile-filter__content">
                <div
                  v-for="filter of $options.mobileFilterOptions"
                  :key="filter"
                >
                  <a
                    href=""
                    @click.prevent="setMobileFilterCategory(filter)"
                    class="mobile-filter-category"
                    :class="{ active: filter === mobileFilterCategory }"
                  >
                    {{ filter }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ais-infinite-hits>
              <div
                slot-scope="{ items, refineNext, isLastPage }"
                class="search-results"
              >
                <div class="search-results__content">
                  <ul class="title-hits">
                    <li v-for="item in items" v-bind:key="item.objectID">
                      <router-link v-bind:to="'/details/' + item.id">
                        {{ item.artist_name }}
                      </router-link>
                    </li>
                    <LoadMore @loadMore="!isLastPage && refineNext()" />
                  </ul>
                </div>
              </div>
            </ais-infinite-hits>
          </div>
        </div>
      </template>
    </ais-state-results>
  </div>
</template>

<script>
import FilterActiveIcon from "@/assets/img/filter_active.svg";
import FilterInactiveIcon from "@/assets/img/filter_inactive.svg";
import { resizeComputed } from "@/services/resizeService";
import { LoadMore } from "@/components/common";
export default {
  name: "Title",
  components: {
    LoadMore,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showFilter: false,
      mobileFilterCategory: "All",
    };
  },
  watch: {
    "screen.width"(screenWidth) {
      if (screenWidth < 768 && this.showFilter) {
        this.onFilterClick();
      }
    },
  },
  computed: {
    ...resizeComputed,
    filterIcon() {
      return this.showFilter ? FilterActiveIcon : FilterInactiveIcon;
    },
    aisQuery() {
      return this.mobileFilterCategory !== "All"
        ? this.mobileFilterCategory
        : "";
    },
  },
  methods: {
    setMobileFilterCategory(category) {
      this.mobileFilterCategory = category;
    },
    getTitlesCount(searchState) {
      if (!searchState.facets.length) return 0;
      console.log(
        "in get titles, ",
        searchState.facets?.[0]?.data,
        searchState
      );
      return Object.values(searchState.facets?.[0]?.data || {}).reduce(
        (acc, count) => acc + count,
        0
      );
    },
    onFilterClick() {
      const showFilter = !this.showFilter;
      const el = this.$refs.headline.$el;
      if (showFilter) {
        const { height } = el.getBoundingClientRect();
        el.parentNode.style.minHeight = `${height}px`;
        let transformX = this.screen.width > 1070 ? `70px` : "80px";
        let transformY = this.screen.width > 1070 ? `-45px` : "-48px";
        el.style.transform = `translate(${transformX}, ${transformY})`;
        el.style.fontSize = "24px";
      } else {
        el.style.transform = "translate(0, 0)";
        const fontSize = this.screen.width > 1070 ? "68px" : "28px";
        el.style.fontSize = fontSize;
      }

      this.showFilter = showFilter;
    },
  },
  mounted() {},
  created() {
    this.$options.mobileFilterOptions = [
      "All",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
  },
};
</script>

<style lang="scss" scoped>
.headline__container {
  margin-top: 20px;
  @include tablet {
    max-width: calc(100% - 135px);
  }
}

::v-deep {
  .base-headline {
    transition: 0.5s all;
  }
}

.view {
  .back {
    top: 50px;
  }
}

.artist-page__content {
  @include mobile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 74px - 12vh);
    overflow: hidden;
  }
}

.legal-name__container {
  @include mobile {
    display: none;
  }
}
.filter-link {
  cursor: pointer;
}

.mobile-filter {
  &__container {
    position: relative;
    overflow: hidden;
    height: 50px;
    margin-bottom: 1rem;
    @include tablet {
      display: none;
    }
  }

  &__content {
    display: flex;
    overflow-x: scroll;
    width: 100vw;

    & > div + div {
      margin-left: 1rem;
    }
  }

  &-category {
    font-weight: 500;
    font-size: 36px;
    &:not(.active) {
      color: $color-grey-light;
    }
    &.active {
      color: $color-primary;
    }
  }
}

.titles-headline__container {
  @include mobile {
    display: none;
  }
}

.title-link {
  @include mobile {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 1.25rem;
  }

  @include tablet-landscape-only {
    font-size: 18px;
  }
}

.filter-icon__container {
  position: absolute;
  top: 50px;
  right: 65px;
  @media (max-width: 1070px) {
    right: 50px;
  }

  @include mobile {
    display: none;
  }
}

.filter-icon {
  @media (max-width: 1070px) {
    max-width: 30px;
  }
}

.filter-input__container {
  position: absolute;
  top: 35px;
  overflow: hidden;
  width: calc(100% - 135px);
}

.filter-input {
  border: none;
  outline: none;
  background: none;
  font-size: 60px;
  width: 100%;
  display: block;
  transition: 0.5s all;
  opacity: 0;
  pointer-events: none;

  &::placeholder {
    color: $color-grey-light;
  }

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
}

.title-hits {
  padding: 0;
  height: 255px;
  overflow: auto;
  margin-bottom: 2rem;
  list-style-type: none;

  & > li {
    margin-bottom: 1rem;
  }
  @include tablet {
    height: 260px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0rem 2rem;
  }

  @include tablet-landscape-only {
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    font-weight: 500;
  }
}

.mobile-artist-name {
  font-weight: 500;

  &__container {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    padding-bottom: 25px;
    @include tablet {
      display: none;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__label {
    display: block;
    color: $color-primary;
    font-weight: 400;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}
</style>
